<template>
  <v-app>
    <!-- Navbar -->
    <NavbarMain />    

    <v-container fluid class="mt-12">
      <v-card class="mt-5" style="max-width: 75%; margin: 0 auto;">
      
        <v-card-title>
          <div class="d-flex align-center justify-space-between w-100">
            <div>
              <h2>{{ profesor.nombre_completo }}</h2>
              <h5>{{ profesor.unidad_academica }}</h5>
            </div>
            <!-- Iconos de redes sociales alineados a la derecha con espacio entre ellos -->
            <div class="d-flex align-center">
              <v-btn icon class="mx-2" @click="compartirEnRed('whatsapp')">
                <v-icon color="green darken-2">mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn icon class="mx-2" @click="compartirEnRed('twitter')">
                <v-icon color="light-blue darken-3">mdi-twitter</v-icon>
              </v-btn>
              <v-btn icon class="mx-2" @click="compartirEnRed('facebook')">
                <v-icon color="blue darken-4">mdi-facebook</v-icon>
              </v-btn>
              <!-- Botón de éxito para evaluar al profesor -->
              <v-btn color="success" class="ml-4" @click="irAEvaluarProfesor">
                <v-icon left>mdi-thumb-up</v-icon> Evaluar Profesor
              </v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card-text>
          <!-- Gráficas de Donut para cada criterio -->
          <v-row>
            <v-col v-for="(promedio, key) in promedios" :key="key" class="d-flex justify-center">
              <div>
                <canvas :id="'criterioDonutChart' + key" class="donut-chart"></canvas>
                <div class="text-center"><h6>{{ getCriterioNombre(key) }}</h6></div>
              </div>
            </v-col>
          </v-row>

          <v-card class="mt-5">
            <v-card-title></v-card-title>
            <v-card-text>
              <canvas id="calificacionesChart"></canvas>
            </v-card-text>
          </v-card>

          <v-list v-if="evaluaciones.length && !noEvaluaciones">
            <v-list-item-group v-for="evaluacion in evaluaciones" :key="evaluacion.materia">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><h4>{{ evaluacion.materia }}</h4></v-list-item-title><br>
                  <v-list-item-subtitle v-if="evaluacion.comentarios && evaluacion.comentarios.length">
                    <v-column>
                      <v-card
                        v-for="(comentario, index) in evaluacion.comentarios"
                        :key="index"
                        class="profesor-card mb-2"
                      >
                        <v-card-text>
                          {{ comentario.texto }}
                          <v-card-subtitle class="fecha-comentario">
                            <br>Publicado: {{ formatFecha(comentario.fecha) }}
                          </v-card-subtitle>
                        </v-card-text>

                        <v-card-actions class="d-flex justify-end align-center">
                          <v-icon class="mx-2" color="green darken-2" @click="logIdAndVote(comentario.id_comentario, 'me_gusta')">
                            mdi-thumb-up
                          </v-icon>
                          <span class="mx-2">{{ votesCounts[comentario.id_comentario]?.me_gusta || 0 }}</span>

                          <v-icon class="mx-2" color="red darken-2" @click="logIdAndVote(comentario.id_comentario, 'no_me_gusta')">
                            mdi-thumb-down
                          </v-icon>
                          <span class="mx-2">{{ votesCounts[comentario.id_comentario]?.no_me_gusta || 0 }}</span>
                        </v-card-actions>
                      </v-card>
                    </v-column>
                  </v-list-item-subtitle>
                  <br>
                  <v-list-item-subtitle v-if="evaluacion.etiquetas && Object.keys(evaluacion.etiquetas).length">
                    <h6> Rasgos de su personalidad:</h6>
                    <v-chip-group>
                      <v-chip v-for="(ocurrencias, etiqueta) in evaluacion.etiquetas" :key="etiqueta" color="primary" class="ma-1" outlined>
                        {{ etiqueta }} ({{ ocurrencias }})
                      </v-chip>
                    </v-chip-group>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-alert v-if="noEvaluaciones" type="info">
            No hay evaluaciones disponibles para este profesor.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-container>

    <v-snackbar v-model="snackbar" color="success" timeout="3000">
      <v-icon left>mdi-check-circle</v-icon>
      {{ snackbarMessage }}
    </v-snackbar>

    <!-- Footer -->
    <v-footer class="grey darken-3 white--text text-center pa-3">
      <v-container>
        <span>&copy; 2024 Santo Profe. Todos los derechos reservados.</span>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import NavbarMain from '@/components/NavbarMain.vue';
import { Chart, registerables } from 'chart.js';

// Registrar componentes necesarios
Chart.register(...registerables);

export default {
  components: {
    NavbarMain, // Asegúrate de registrar el componente aquí
  },
  data() {
    return {
      profesor: {
        nombre_completo: '',
        unidad_academica: '',
      },
      evaluaciones: [],
      votesCounts: {}, // Añadido para almacenar el conteo de votos
      noEvaluaciones: false,
      snackbar: false,
      snackbarMessage: '',
      promedios: {
        '1': 0, // Claridad
        '2': 0, // Dominio del Tema
        '3': 0, // Interacción
        '4': 0, // Puntualidad
        '5': 0, // Uso de Recursos
      },
      donutCharts: {}, // Almacenar las instancias de los gráficos de donut

    };
  },
  async created() {
    await this.loadData(); // Utiliza un método separado para cargar los datos
  },
  methods: {
    getCriterioNombre(key) {
    const criterios = {
      '1': 'Claridad',
      '2': 'Dominio del Tema',
      '3': 'Interacción',
      '4': 'Puntualidad',
      '5': 'Uso de Recursos'
    };
    return criterios[key] || 'Desconocido';
  },
    async loadData() {
      const profesorId = this.$route.params.profesorId;

      try {
        const response = await this.$axios.get(`/get_profesor_details.php?profesor_id=${profesorId}`);
        const data = response.data;
        console.log("Datos recibidos:", data); // Agregar este log
        if (data.error) {
          this.noEvaluaciones = true;
          this.profesor = { nombre_completo: 'No encontrado', unidad_academica: '' };
        } else {
          this.profesor = data.profesor;
          this.evaluaciones = data.evaluaciones;
          this.promedios = data.promedios_criterios || {}; // Cargar los promedios por criterio, manejar undefined
          this.noEvaluaciones = this.evaluaciones.length === 0;
          
          this.$nextTick(() => {
          this.createDonutCharts(); // Crear las gráficas de donuts después de cargar los datos y actualizar el DOM
        });
          
          await this.fetchVotesCounts(); // Obtén el conteo de votos después de obtener las evaluaciones
          this.createChart();
        }
      } catch (error) {
        console.error("Error fetching professor details:", error);
      }
    },
    formatPromedio(valor) {
      return valor !== undefined && valor !== 'N/A' ? valor.toFixed(2) : 'N/A';
    },
    async updateData() {
      await this.loadData(); // Recarga los datos
    },
    irAEvaluarProfesor() {
      const profesorId = this.$route.params.profesorId;
      this.$router.push({ name: 'EvaluarProfesor', params: { profesorId } });
    },
    irAlInicio() {
      this.$router.push({ name: 'Home' });
    },
    formatFecha(fecha) {
      const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(fecha).toLocaleDateString('es-ES', opciones);
    },

    createDonutCharts() {
      Object.keys(this.promedios).forEach((key) => {
        const chartId = 'criterioDonutChart' + key;
        const ctx = document.getElementById(chartId).getContext('2d');
        const color = this.getColorForPromedio(this.promedios[key]);

        // Destruir gráfico existente si lo hay
        if (this.donutCharts[chartId]) {
          this.donutCharts[chartId].destroy();
        }

        // Crear un nuevo gráfico y almacenarlo en donutCharts
        this.donutCharts[chartId] = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: [this.getCriterioNombre(key)],
            datasets: [{
              data: [this.promedios[key], 100 - this.promedios[key]],
              backgroundColor: [
                color,
                '#E0E0E0'
              ],
              hoverBackgroundColor: [
                color,
                '#E0E0E0'
              ]
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false, // Permite que el gráfico se ajuste al tamaño del canvas
            cutout: '50%', // Ajusta el tamaño del recorte interno
            plugins: {
              legend: {
                display: false // Oculta las leyendas
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return `${context.label}: ${context.raw.toFixed(2)}%`;
                  }
                }
              }
            }
          }
        });
      });
    },
    getColorForPromedio(promedio) {
      if (promedio >= 80) {
        return 'green';
      } else if (promedio >= 60) {
        return '#EAEA00';
      } else {
        return 'red';
      }
    },
  

    createChart() {
      const ctx = document.getElementById('calificacionesChart').getContext('2d');
      const labels = this.evaluaciones.map(evaluacion => evaluacion.materia);
      const data = this.evaluaciones.map(evaluacion => evaluacion.promedio_calificacion);

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: 'Promedio de Calificaciones',
            data: data,
            backgroundColor: labels.map((_, index) => this.getColorForIndex(index)),
            borderColor: labels.map((_, index) => this.getColorForIndex(index)),
            borderWidth: 1,
          }]
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              titleColor: '#fff',
              bodyColor: '#fff',
              cornerRadius: 5,
              padding: 10,
              displayColors: false,
              callbacks: {
                label: function(context) {
                  return ` Promedio: ${context.raw.toFixed(2)}`;
                }
              }
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'end',
              formatter: function(value) {
                return value.toFixed(2);
              },
              color: '#000',
              font: {
                family: 'Roboto',
                weight: 'bold',
                size: 12
              }
            }
          },
          scales: {
            x: {
              beginAtZero: true,
              min: 0,
              max: 100,
              ticks: {
                color: '#333',
                font: {
                  size: 12,
                  family: 'Roboto',
                },
              },
              grid: {
                color: 'rgba(200, 200, 200, 0.3)',
              }
            },
            y: {
              ticks: {
                color: '#333',
                font: {
                  size: 12,
                  family: 'Roboto',
                },
              },
              grid: {
                color: 'rgba(200, 200, 200, 0.3)',
              }
            }
          },
          elements: {
            bar: {
              borderRadius: 5,
              borderSkipped: false,
            }
          }
        }
      });
    },
    getColorForIndex(index) {
      const colors = ['#2B3E50', '#5A6D7D', '#8D98A7', '#C0D1DA', '#DFE5E8'];
      return colors[index % colors.length];
    },
    compartirEnRed(red) {
      const url = window.location.href;
      const text = `Revisa los detalles sobre el profesor ${this.profesor.nombre_completo} en nuestra plataforma Santo Profe: ${url}`;
      let shareUrl = '';
      let redName = '';

      switch (red) {
        case 'whatsapp':
          shareUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
          redName = 'WhatsApp';
          break;
        case 'facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
          redName = 'Facebook';
          break;
        case 'twitter':
          shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
          redName = 'Twitter';
          break;
      }

      window.open(shareUrl, '_blank');
      this.snackbarMessage = `Compartido en ${redName}!`;
      this.snackbar = true;
    },

    
    async obtenerDireccionIp() {
      try {
        const response = await this.$axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
      } catch (error) {
        console.error("Error al obtener la dirección IP:", error);
        return '0.0.0.0'; // Valor por defecto en caso de error
      }
    },

    async fetchVotesCounts() {
      try {
        const response = await this.$axios.get('/get_votes_count.php');
        const data = response.data;
        if (data.success) {
          this.votesCounts = data.votes_counts;
        }
      } catch (error) {
        console.error('Error fetching votes counts:', error);
      }
    },

    logIdAndVote(idEvaluacion, tipoVoto) {
      console.log("ID de la evaluación:", idEvaluacion); // Verifica que idEvaluacion esté correcto
      console.log("Tipo de voto:", tipoVoto); // Verifica que tipoVoto esté correcto
      this.votar(idEvaluacion, tipoVoto);
    },
    
    async votar(idEvaluacion, tipoVoto) {
      try {
        const direccionIp = await this.obtenerDireccionIp();
        const response = await this.$axios.post('/votar_comentario.php', {
          id_evaluacion: idEvaluacion,
          tipo_voto: tipoVoto,
          direccion_ip: direccionIp
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data.success) {
          this.snackbarMessage = 'Tu opinión se ha registrado correctamente';
          this.snackbar = true;

          // Actualiza el conteo de votos y vuelve a cargar los datos de la evaluación
          await this.updateData(); // Llama al método de actualización de datos
        } else {
          this.snackbarMessage = response.data.message;
          this.snackbar = true;
        }
      } catch (error) {
        console.error("Error al registrar el voto:", error);
        this.snackbarMessage = 'Error al registrar el voto';
        this.snackbar = true;
      }
    }
  }
};
</script>


<style scoped>
/* Estilo personalizado para encabezados h1 a h6 con tonos de verdes */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif !important; /* Aplicando fuente Roboto */
}

h1 {
  font-size: 2.5rem !important;
  font-weight: bold !important;
  color: #003d34 !important; /* Verde Bosque Profundo */
}

h2 {
  font-size: 2rem !important;
  font-weight: bold !important;
  color: #004d40 !important; /* Verde Cazador */
}

h3 {
  font-size: 1.75rem !important;
  font-weight: bold !important;
  color: #00796b !important; /* Verde Mar */
}

h4 {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: #004d40 !important; /* Verde Cazador */
}

h5 {
  font-size: 1.25rem !important;
  font-weight: bold !important;
  color: #009688 !important; /* Verde Teal */
}

h6 {
  font-size: 1rem !important;
  font-weight: bold !important;
  color: #0f524b !important; /* Verde Agua Suave */
}

/* Estilos para los comentarios y fechas */
.profesor-card {
  max-width: 90%;
  margin: 0 auto;
}

.v-card-text {
  font-size: 0.85rem !important;
  font-weight: bold !important;
  color: #000000 !important; /* Asegurando el color del texto dentro de las tarjetas */
  font-family: 'Roboto', sans-serif !important; /* Aplicando fuente Roboto */
}

.v-card-title, .v-card-subtitle {
  font-size: 0.75rem;
  color: #003d34 !important; /* Verde Bosque Profundo */
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif !important; /* Aplicando fuente Roboto */
}

/* Asegurando que los chips dentro de las tarjetas tengan el estilo deseado */
.v-chip {
  color: #FFFFFF !important;
  background-color: #009688 !important; /* Color de fondo de los chips */
}

canvas {
  width: 100% !important;
  height: 220px !important;
  max-width: 100% !important;
  font-family: 'Roboto', sans-serif !important;
}

.ma-1 {
  margin: 4px !important;
}

.icon-buttons .v-btn {
  margin: 0 5px; /* Ajusta el valor según sea necesario */
}

/* Estilos específicos para las gráficas de donut */
.donut-chart {
  width: 120px !important; /* Ajusta el tamaño según sea necesario */
  height: 120px !important;
}


/* Añade el estilo para el navbar y el footer aquí */
.v-app-bar {
  background-color: #2c3e50 !important; /* El mismo color que el footer */
}

/* Estilo para el footer */
.v-footer {
  background-color: #2c3e50; /* Fondo oscuro */
  color: #ecf0f1; /* Texto en color claro */
  padding: 16px; /* Espacio alrededor del contenido */
  text-align: center; /* Alineación central del texto */
  font-family: 'Roboto', sans-serif; /* Fuente consistente con el resto de la aplicación */
  font-size: 14px; /* Tamaño de fuente moderado */
  border-top: 2px solid #34495e; /* Línea superior para dar contraste */
}

.v-footer a {
  color: #ecf0f1; /* Color del enlace */
  text-decoration: none; /* Sin subrayado */
  font-weight: bold; /* Texto en negrita */
}

.v-footer a:hover {
  color: #3498db; /* Color del enlace al pasar el mouse */
  text-decoration: underline; /* Subrayado al pasar el mouse */
}

.v-footer .footer-logo {
  max-height: 50px; /* Tamaño máximo del logotipo */
  margin-bottom: 8px; /* Espacio debajo del logotipo */
}


</style>
