// src/plugins/vuetify.js
import 'vuetify/styles'; // Importa estilos de Vuetify
import { createVuetify } from 'vuetify';

// Importa el paquete de íconos deseado
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Importa Font Awesome


const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        
      },
    },
  },
});

export default vuetify;
