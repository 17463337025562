import { createRouter, createWebHistory } from 'vue-router';
import HomeMain from '../views/HomeMain.vue';
import ProfesorDetails from '../views/ProfesorDetails.vue'; // Asegúrate de tener esta vista
import EvaluarProfesor from '../views/EvaluarProfesor.vue'; // Importa la nueva vista

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeMain,
  },
  {
    path: '/profesor/:profesorId',
    name: 'ProfesorDetails',
    component: ProfesorDetails,
  },
  {
    path: '/evaluar-profesor/:profesorId',
    name: 'EvaluarProfesor',
    component: EvaluarProfesor,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;