<template>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title @click="irAlInicio">Santo Profe</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="irAEvaluarProfesor">Evaluar al Profesor</v-btn>
      <v-btn text @click="irAlInicio">Inicio</v-btn>
  
      <v-navigation-drawer v-model="drawer" app>
        <v-list>
          <v-list-item @click="irAEvaluarProfesor">
            <v-list-item-title>Evaluar Profesor</v-list-item-title>
          </v-list-item>
          <v-list-item @click="irAlInicio">
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
  </template>
  
  
  <script>
  export default {
    data() {
      return {
        drawer: false,
      };
    },
    methods: {
      irAEvaluarProfesor() {
        this.$router.push({ name: 'EvaluarProfesor' });
      },
      irAlInicio() {
        this.$router.push({ name: 'Home' });
      },
      compartirEnRed(red) {
        const url = window.location.href;
        const text = `Revisa los detalles sobre el profesor en nuestra plataforma Santo Profe: ${url}`;
        let shareUrl = '';
  
        switch (red) {
          case 'whatsapp':
            shareUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
            break;
          case 'facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            break;
          case 'twitter':
            shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
            break;
        }
  
        window.open(shareUrl, '_blank');
      },
    },
  };
  </script>
  
  <style scoped>
  .v-btn {
    margin-left: 8px; /* Espacio entre iconos sociales */
  }
  </style>
  