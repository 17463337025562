import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import '@mdi/font/css/materialdesignicons.css'; // Asegúrate de importar los estilos de Material Design Icons


// Define la URL base para Axios directamente
axios.defaults.baseURL = 'https://santo-profe.innmad.com/backend';

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.config.globalProperties.$axios = axios;
app.mount('#app');

