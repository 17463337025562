<template>
  <v-app>
    <!-- Contenedor con fondo gris para el encabezado -->
    <!--<v-container fluid class="grey-background">-->
    <v-container fluid class="pattern-background">

      <!-- Encabezado del proyecto -->
      <v-card class="mb-1 header-card">
        <br>
        <v-img
          src="../assets/santo_profe.png"
          alt="Encabezado del proyecto"
          height="150px"
          class="grey lighten-2 heartbeat"
          width="100%"
        ></v-img>
        <v-card-title>
          <h1>Santo Profe</h1>
        </v-card-title>
        <v-card-text>
          <h6>
            ¡Hola! 🎉 Bienvenido a tu aliado en la búsqueda de los mejores profesores de tu institución. Aquí, puedes <br>
            revisar lo que otros estudiantes opinan sobre sus clases y profesores, dándote la información que necesitas <br>
            para tomar decisiones más acertadas al elegir tus materias. 🚀<br><br>
            Tu participación no solo te ayuda a ti, sino también a tus compañeros 📚 ¡Anímate a ser parte de esta comunidad!🌟
           </h6>
        </v-card-text>
      </v-card>
    </v-container>

<!-- Logos Contenedor con Efecto Marquesina -->
<v-container fluid>
  <v-card class="mb-2 marquee-card">
    <div class="marquee-wrapper">
      <div class="marquee">
        <img
          :src="require('@/assets/upiita.png')"
          alt="Logotipo UPIITA"
          height="60px"
          class="logo"
        >
        <img
          :src="require('@/assets/esime_azcapo.png')"
          alt="Logotipo ESIME Azcapotzalco"
          height="60px"
          class="logo"
        >
        <!-- Añade más logotipos si es necesario -->
      </div>
    </div>
  </v-card>
</v-container>


    <!-- Cuadro de búsqueda -->
    <v-container fluid>
      <v-card class="mb-2 search-card">
        <v-text-field
          label="Buscar profesor por nombre y apellidos"
          v-model="searchQuery"
          clearable
          outlined
          class="mx-auto search-field"
          @input="filterBySearch"
        ></v-text-field>
      </v-card>
    </v-container>

    <!-- Botones por letra inicial -->
    <v-container fluid>
      <v-card class="mb-2">
        <div class="button-container pa-4">
          <v-btn
            v-for="letter in letters"
            :key="letter"
            @click="filterByLetter(letter)"
            class="mx-1"
            outlined
          >
            {{ letter }}
          </v-btn>
        </div>
      </v-card>
    </v-container>

    <!-- Lista de profesores filtrados en cuatro columnas -->

    <v-container fluid style="max-width: 90%; margin: 0 auto;">
  <v-row>
    <v-col v-for="profesor in filteredProfesores" :key="profesor.id_profesor" cols="12" sm="6" md="3">
      <v-card 
        class="mb-2 professor-card"
        outlined
      >
        <v-list-item @click="viewEvaluations(profesor.id_profesor)" class="cursor-pointer">
          <v-list-item-content>
            <v-list-item-title class="headline">
              {{ profesor.nombre_completo }}
              <v-icon :color="getCalificacionColor(profesor.promedio_calificacion)" class="ml-2">
                {{ getCalificacionIcon(profesor.promedio_calificacion) }}
              </v-icon>
            </v-list-item-title>
            <v-list-item-subtitle>{{ profesor.unidad_academica }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</v-container>
<br><br><br>

    <!-- Alerta de error -->
    <v-container fluid>
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
    </v-container>
  
  
   <!-- Footer -->
   <v-footer class="grey darken-3 white--text text-center pa-3">
    <v-container>
      <span>&copy; 2024 Santo Profe. Todos los derechos reservados.</span>
    </v-container>
  </v-footer>
  
  
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      profesores: [],
      error: null,
      filteredProfesores: [], // Mantiene vacío inicialmente
      letters: [],
      searchQuery: "",
    };
  },
  methods: {
    async fetchProfesores() {
      try {
        const response = await this.$axios.get("/get_profesores.php");
        this.profesores = response.data;
        this.extractLetters();
      } catch (error) {
        this.error = "Error fetching profesores: " + error.message;
        console.error("Error fetching profesores:", error);
      }
    },
    extractLetters() {
      const uniqueLetters = new Set();
      this.profesores.forEach((profesor) => {
        const initial = profesor.nombre_completo.charAt(0).toUpperCase();
        uniqueLetters.add(initial);
      });
      this.letters = Array.from(uniqueLetters).sort();
    },
    filterByLetter(letter) {
      this.searchQuery = "";
      this.filteredProfesores = this.profesores.filter((profesor) =>
        profesor.nombre_completo.startsWith(letter)
      );
    },
    filterBySearch() {
      const query = this.searchQuery.toLowerCase();
      if (query) {
        this.filteredProfesores = this.profesores.filter((profesor) =>
          profesor.nombre_completo.toLowerCase().includes(query)
        );
      } else {
        this.filteredProfesores = []; // Si el cuadro de búsqueda está vacío, vacía filteredProfesores
      }
    },
    getCalificacionIcon(promedio) {
      if (promedio === null) return 'mdi-emoticon-dead-outline'; // Para calificaciones nulas
      if (promedio >= 80) return 'mdi-emoticon-happy-outline';
      if (promedio >= 60) return 'mdi-emoticon-neutral-outline';
      if (promedio >= 40) return 'mdi-emoticon-sad-outline';
      return 'mdi-emoticon-dead-outline'; // Para calificaciones muy bajas
    },
    getCalificacionColor(promedio) {
      if (promedio === null) return 'grey'; // Color gris para calificaciones nulas
      if (promedio >= 80) return 'green';
      if (promedio >= 60) return 'orange';
      if (promedio >= 40) return 'red';
      return 'red'; // Color para calificaciones muy bajas
    },
    viewEvaluations(profesorId) {
      this.$router.push({ name: 'ProfesorDetails', params: { profesorId } });
    },
  },
  mounted() {
    this.fetchProfesores();
  },
};
</script>

<style scoped>

/* Estilo personalizado para encabezados h1 a h6 con tonos de verdes */
h1, h2, h3, h4, h5 {
  font-family: 'Roboto', sans-serif !important; /* Aplicando fuente Roboto */
  transition: color 0.3s ease-in-out;
}

h1 {
  font-size: 2.5rem !important;
  font-weight: bold !important;
  color: #003d34 !important; /* Verde Bosque Profundo */
}

h2 {
  font-size: 2rem !important;
  font-weight: bold !important;
  color: #004d40 !important; /* Verde Cazador */
}

h3 {
  font-size: 1.75rem !important;
  font-weight: bold !important;
  color: #00796b !important; /* Verde Mar */
}

h4 {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: #004d40 !important; /* Verde Cazador */
}

h5 {
  font-size: 1.25rem !important;
  font-weight: bold !important;
  color: #009688 !important; /* Verde Teal */
}

h6 {
  font-family: 'Poppins', sans-serif !important; /* Cambia la fuente a Poppins */
  font-size: 0.90rem !important;
  font-weight: bold !important;
  color: #00796b !important; /* Verde Mar */
}

.header-card {
  background-color: transparent;
  box-shadow: none !important; /* Asegura que no haya sombra */
}

/* Animación de fadeIn */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Estilo para las demás tarjetas y contenedores */
.logos-wrapper, .button-container, .v-card {
  text-align: center;
  background-color: transparent;
  box-shadow: none;
}

/* Hover Effect para botones */
.v-btn {
  border: 1px solid #42a5f5;
  transition: background-color 0.3s, color 0.3s;
}

.v-btn:hover {
  background-color: #42a5f5;
  color: #fff;
}

/* Sombras suaves para las cartas */
.v-card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.v-card:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Transición en los iconos */
.v-icon {
  transition: color 0.3s ease-in-out;
}

.v-icon:hover {
  color: #42a5f5;
}

/* Search Field Styles */
.search-card {
  max-width: 600px;
  margin: 0 auto;
}

.search-field {
  width: 100%;
}

.v-list {
  max-width: 800px;
  margin: auto;
}

/* Estilo para el footer */
.v-footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 16px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  border-top: 2px solid #34495e;
}

.v-footer a {
  color: #ecf0f1;
  text-decoration: none;
  font-weight: bold;
}

.v-footer a:hover {
  color: #3498db;
  text-decoration: underline;
}

.v-footer .footer-logo {
  max-height: 50px;
  margin-bottom: 8px;
}
.marquee-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px; /* Ajusta la altura del card según sea necesario */
}

.marquee-wrapper {
  overflow: hidden; /* Oculta las imágenes fuera del contenedor */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
  background-color: transparent; /* Fondo opcional */
  position: relative; /* Necesario para la animación */
}

.marquee {
  display: flex;
  align-items: center;
  animation: marquee 25s linear infinite; /* Animación de marquesina */
}

.marquee img {
  margin-right: 50px; /* Espacio entre las imágenes */
  flex-shrink: 0; /* Evita que las imágenes se reduzcan */
}

@keyframes marquee {
  from {
    transform: translateX(100%); /* Inicia fuera de la vista a la derecha */
  }
  to {
    transform: translateX(-10%); /* Se mueve fuera de la vista a la izquierda */
  }
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.heartbeat {
  animation: heartbeat 1.5s ease-in-out;
  animation-iteration-count: 3;
}

@keyframes heartbeatInterval {
  0%, 97% {
    transform: scale(1);
  }
  98%, 100% {
    transform: scale(1);
  }
}

.heartbeat-container {
  animation: heartbeatInterval 30s infinite;
}

.heartbeat-container:hover .heartbeat {
  animation-play-state: running;
}
/* Estilo personalizado para el fondo con patrón repetido */
.pattern-background {
  background-image: url('../assets/patron.png'); /* Reemplaza con la ruta de tu imagen de patrón */
  background-repeat: repeat; /* Repite la imagen para llenar el contenedor */
  background-size: auto; /* Ajusta el tamaño de la imagen según sea necesario */
  padding: 20px; /* Opcional: Añade padding para darle espacio al contenido */
  border-radius: 8px; /* Bordes redondeados */
}

/* Otros estilos... */
.professor-card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;
  border-radius: 12px;
  background: linear-gradient(135deg, #34495E 0%, #2C3E50 100%);
  color: #ECF0F1;
}

.professor-card:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

</style>
