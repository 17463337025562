<template>
  <v-app>
    <!-- Navbar -->
    <NavbarMain />    
    <v-container fluid class="mt-12">
      <v-card class="mt-5" style="max-width: 75%; margin: 0 auto;"> <!-- Ajustado el ancho máximo -->
        <v-card-title >
          <h2>{{ profesor.nombre_completo }}</h2>
          <h5>{{ profesor.unidad_academica }}</h5>
        </v-card-title>
      </v-card>
        
      <!-- Sección de selección de materias -->
      <v-card class="mt-5 mb-4" style="max-width: 75%; margin: 0 auto;"> <!-- Ajustado el ancho máximo -->
        <v-card-title><h4>Crea una asignatura o selecciona de las disponibles</h4></v-card-title><br>
        <v-card-text>
          <v-row>
            <v-col
              v-for="materia in materias"
              :key="materia.id_materia"
              cols="auto"
              class="pa-2"
            >
              <v-card
                :class="{ 'selected-card': selectedMateria === materia.id_materia }"
                @click="selectedMateria = materia.id_materia"
                class="materia-card"
                outlined
                hover
                ripple
              >
                <v-card-title>{{ materia.nombre }}</v-card-title>
              </v-card>
            </v-col>
          </v-row>

          <!-- Botón para abrir diálogo de nueva materia -->
          <br><br>
          <v-btn color="success" class="mb-4" @click="dialog = true">
            <v-icon left>mdi-plus</v-icon> Crear Nueva Asignatura
          </v-btn>

          <!-- Diálogo para agregar nueva materia -->
          <v-dialog v-model="dialog" max-width="500">
            <v-card>
              <v-card-title>
                <span class="headline">Nueva Materia</span>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="nuevaMateria"
                  label="Nombre de la nueva materia"
                  placeholder="Ingrese el nombre de la nueva materia"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  <v-icon left>mdi-cancel</v-icon> Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="agregarMateria">
                  <v-icon left>mdi-content-save</v-icon> Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>

      <!-- Sección de criterios de evaluación -->
      <v-card class="mb-4" style="max-width: 75%; margin: 0 auto;"> <!-- Ajustado el ancho máximo -->
        <v-card-title><h4>Criterios de Evaluación</h4></v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="criterio in criterios"
              :key="criterio.id_criterio"
              xs12
              sm6
              md4
              class="pa-0"
            >
              <v-card outlined class="mb-2">
                <v-card-title>
                  <h5>{{ criterio.nombre }}</h5>
                </v-card-title>
                <v-card-subtitle>{{ criterio.descripcion }}</v-card-subtitle>
                <v-card-text>
                  <v-rating
                    v-model="evaluacion[criterio.id_criterio]"
                    :length="5"
                    :item-labels="ratingLabels"
                    background-color="grey lighten-1"
                    color="#FFD700"
                    dense
                    size="large"
                    class="rating-spacing"
                  ></v-rating>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Sección de etiquetas -->
      <v-card class="mb-4" style="max-width: 75%; margin: 0 auto;"> <!-- Ajustado el ancho máximo -->
        <v-card-title><h4>Rasgos de personalidad</h4></v-card-title><br>
        <v-card-text>
          <v-row>
            <v-col cols="auto" v-for="etiqueta in etiquetas" :key="etiqueta.id_etiqueta" class="pa-1">
              <v-chip
                :input-value="selectedEtiquetas.includes(etiqueta.id_etiqueta)"
                @click="toggleEtiqueta(etiqueta.id_etiqueta)"
                outlined
                color="primary"
                label
                class="rounded-pill"
                :class="{'selected-chip': selectedEtiquetas.includes(etiqueta.id_etiqueta), 'unselected-chip': !selectedEtiquetas.includes(etiqueta.id_etiqueta)}"
              >
                {{ etiqueta.nombre }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Sección de comentarios -->
      <v-card class="mb-4" style="max-width: 75%; margin: 0 auto;"> <!-- Ajustado el ancho máximo -->
        <v-card-title><h4>Comentarios constructivos</h4></v-card-title>
        <v-card-text>
          <v-textarea
            v-model="comentario"
            label="Por favor comparte tu opinión de manera constructiva"
            rows="3"
            outlined
            dense
          ></v-textarea>
        </v-card-text>
      </v-card>

      <!-- Botón de envío -->
      <v-card class="mb-4" style="max-width: 75%; margin: 0 auto;"> <!-- Ajustado el ancho máximo -->
      <v-btn type="submit" color="primary" class="mt-4" @click="submitEvaluation">
        <v-icon left>mdi-send</v-icon>  Enviar Evaluación
      </v-btn>
      </v-card>

      <!-- Snackbar -->
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
        top
        right
      >
        {{ snackbarMessage }}
      </v-snackbar>
    </v-container>

    <!-- Footer -->
    <v-footer class="grey darken-3 white--text text-center pa-3">
      <v-container>
        <span>&copy; 2024 Santo Profe. Todos los derechos reservados.</span>
      </v-container>
    </v-footer>
  </v-app>
</template>


<script>
import NavbarMain from '@/components/NavbarMain.vue';


export default {
  components: {
    NavbarMain, // Asegúrate de registrar el componente aquí
  },
  data() {
    return {
      profesor: {
        nombre_completo: "",
        unidad_academica: "",
      },
      materias: [],
      selectedMateria: null,
      nuevaMateria: "",
      dialog: false,
      criterios: [],
      evaluacion: {}, // Este objeto se inicializa correctamente en created()
      comentario: "", // Comentario general
      etiquetas: [],
      selectedEtiquetas: [],
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      snackbarTimeout: 3000,
      ratingLabels: [
        "Deficiente",
        "Regular",
        "Bueno",
        "Muy Bueno",
        "Excelente",
      ],
    };
  },
  async created() {
    const profesorId = this.$route.params.profesorId;

    try {
      // Obtener detalles del profesor
      const profesorResponse = await this.$axios.get(
        `/get_profesor_details.php?profesor_id=${profesorId}`
      );
      this.profesor = profesorResponse.data.profesor;

      // Obtener materias del profesor
      const materiasResponse = await this.$axios.get(
        `/get_materias.php?profesor_id=${profesorId}`
      );
      this.materias = materiasResponse.data.materias || [];

      // Inicializar selectedMateria si hay materias
      if (this.materias.length > 0) {
        this.selectedMateria = this.materias[0].id_materia;
      }

      // Obtener criterios de evaluación
      const criteriosResponse = await this.$axios.get("/get_criterios.php");
      this.criterios = criteriosResponse.data.criterios;

      // Inicializar objeto de evaluación
      this.criterios.forEach((criterio) => {
        this.evaluacion[criterio.id_criterio] = 0; // Inicializar a 0 para el control de v-rating
      });

      // Obtener etiquetas
      const etiquetasResponse = await this.$axios.get("/get_etiquetas.php");
      this.etiquetas = etiquetasResponse.data.etiquetas;
    } catch (error) {
      console.error("Error fetching evaluation data:", error);
    }
  },
  methods: {
    async agregarMateria() {
      if (!this.nuevaMateria.trim()) {
        this.showSnackbar("Por favor, ingrese un nombre para la nueva materia.", "error");
        return;
      }

      try {
        // Enviar nueva materia al backend
        const response = await this.$axios.post("/add_materia.php", {
          id_profesor: this.$route.params.profesorId,
          nombre: this.nuevaMateria.trim(),
        });

        const nuevaMateria = response.data.materia;
        this.materias.push(nuevaMateria);
        this.selectedMateria = nuevaMateria.id_materia;
        this.nuevaMateria = ""; // Limpiar el campo de nueva materia
        this.dialog = false; // Cerrar el diálogo
        this.showSnackbar("Materia agregada con éxito.", "success");
      } catch (error) {
        console.error("Error adding new materia:", error);
      }
    },
    async submitEvaluation() {
  // Validar que una materia esté seleccionada
  if (!this.selectedMateria) {
    this.showSnackbar("Por favor, seleccione una materia antes de enviar la evaluación.", "error");
    return;
  }

  // Validar que se haya evaluado cada criterio
  const criteriosIncompletos = Object.keys(this.evaluacion).some(
    (criterioId) => this.evaluacion[criterioId] === 0
  );

  if (criteriosIncompletos) {
    this.showSnackbar("Por favor, evalúe todos los criterios antes de enviar la evaluación.", "error");
    return;
  }

  // Validar que se haya ingresado un comentario
  if (!this.comentario.trim()) {
    this.showSnackbar("Por favor, ingrese un comentario antes de enviar la evaluación.", "error");
    return;
  }

  const profesorId = this.$route.params.profesorId;
  const evaluacionData = {
    profesor_id: profesorId,
    materia_id: this.selectedMateria,
    detalles: Object.keys(this.evaluacion).map((criterioId) => ({
      id_criterio: parseInt(criterioId, 10),
      calificacion: this.evaluacion[criterioId],
    })),
    comentario: this.comentario, // Comentario general para toda la evaluación
    etiquetas: this.selectedEtiquetas, // Etiquetas seleccionadas
  };

  try {
    // Enviar evaluación al backend
    await this.$axios.post("/submit_evaluation.php", evaluacionData);
    this.showSnackbar("Evaluación enviada con éxito.", "success");

    // Redirigir a la página de detalles del profesor
    this.$router.push({
      name: 'ProfesorDetails',
      params: { profesorId },
    });
  } catch (error) {
    console.error("Error submitting evaluation:", error);
    this.showSnackbar("Error al enviar la evaluación.", "error");
  }
},

    toggleEtiqueta(idEtiqueta) {
      const index = this.selectedEtiquetas.indexOf(idEtiqueta);
      if (index === -1) {
        this.selectedEtiquetas.push(idEtiqueta);
      } else {
        this.selectedEtiquetas.splice(index, 1);
      }
    },
    showSnackbar(message, color) {
      this.snackbarMessage = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>

/* Estilo personalizado para encabezados h1 a h6 con tonos de verdes */
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #003d34; /* Verde Bosque Profundo */
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #004d40; /* Verde Cazador */
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
}

h3 {
  font-size: 1.75rem;
  font-weight: bold;
  color: #00796b; /* Verde Mar */
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004d40; /* Verde Cazador */
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
}

h5 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #009688; /* Verde Teal */
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
}

h6 {
  font-size: 1rem;
  font-weight: bold;
  color: #0f524b; /* Verde Agua Suave */
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
}


.v-card-title,
.v-card-subtitle {
  font-size: 14px; /* Ajustar el tamaño de la fuente para los subtítulos */
}

.v-card-text {
  font-size: 10px; /* Ajustar el tamaño de la fuente para el texto */
}

.rating-spacing .v-rating__item {
  margin-right: 12px; /* Aumenta el espacio entre las estrellas */
  font-size: 32px; /* Aumenta el tamaño de las estrellas */
}

.rating-spacing .v-rating__item:last-child {
  margin-right: 0; /* Elimina el margen derecho en la última estrella */
}

.rating-spacing .v-rating__item svg {
  fill: #ffd700; /* Cambiar el color de las estrellas a dorado */
}

.selected-card {
  background-color: #f0f0f0;
  border: 2px solid #1976d2; /* Color de borde azul para la tarjeta seleccionada */
  margin: 4px; /* Reducir el margen entre las tarjetas */
}

.v-card {
  padding: 0; /* Reducir el padding dentro de las tarjetas */
}


.selected-chip {
  background-color: #1976d2;
  color: white !important; /* Texto blanco */
}

.unselected-chip {
  background-color: #e0e0e0;
  color: black;
}

/* Añade el estilo para el navbar y el footer aquí */
.v-app-bar {
  background-color: #2c3e50 !important; /* El mismo color que el footer */
}


/* Estilo para el footer */
.v-footer {
  background-color: #2c3e50; /* Fondo oscuro */
  color: #ecf0f1; /* Texto en color claro */
  padding: 16px; /* Espacio alrededor del contenido */
  text-align: center; /* Alineación central del texto */
  font-family: 'Roboto', sans-serif; /* Fuente consistente con el resto de la aplicación */
  font-size: 14px; /* Tamaño de fuente moderado */
  border-top: 2px solid #34495e; /* Línea superior para dar contraste */
}

.v-footer a {
  color: #ecf0f1; /* Color del enlace */
  text-decoration: none; /* Sin subrayado */
  font-weight: bold; /* Texto en negrita */
}

.v-footer a:hover {
  color: #3498db; /* Color del enlace al pasar el mouse */
  text-decoration: underline; /* Subrayado al pasar el mouse */
}

.v-footer .footer-logo {
  max-height: 50px; /* Tamaño máximo del logotipo */
  margin-bottom: 8px; /* Espacio debajo del logotipo */
}

.materia-card {
  background-color: #34495E; /* Color de fondo gris azulado */
  color: #ECF0F1; /* Texto blanco para el nombre de la materia */
  border-radius: 8px;
  transition: background-color 0.1s, box-shadow 0.1s, border 0.1s, color 0.1s;
}

.materia-card:hover {
  background-color: #2C3E50; /* Un tono más oscuro en hover */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.selected-card {
  border: 2px solid #E74C3C; /* Borde rojo vibrante para mayor contraste */
  box-shadow: 0px 0px 12px rgba(231, 76, 60, 0.2); /* Sombra para destacar más */
  background-color: #E74C3C; /* Fondo rojo más intenso */
  color: #FFFFFF; /* Texto blanco sobre el fondo rojo */
}

.materia-title {
  color: inherit; /* Hereda el color de la tarjeta, asegurando el contraste adecuado */
  font-weight: bold; /* Resalta el texto */
}
</style>
